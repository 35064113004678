import React from 'react'
import PropTypes from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'
import { setWarrantyEnabled } from '@helpers/cart'
import { PriceAtom } from '@rtgdev/design-system'

const AddonWarranty = ({
  cartIndex,
  dtIdPrefix = '',
  productQuantity,
  productSKU,
  setShowWarrantyModal,
  warrantyEnabled,
  warrantyPrice,
}) => (
  <>
    <div className="addon-header add" role="heading" aria-level="3">
      Protect Your Purchase:
    </div>
    <ul className="addon-group">
      <li key={`${productSKU}-${cartIndex}-addon-warranty-plan`}>
        <div className="grid-x">
          <div
            className="small-10 large-11 protection-plan-section"
            data-testid={`${dtIdPrefix}protection-plan-checkbox`}
          >
            <input
              id={`warrantyCheckBox${cartIndex}`}
              type="checkbox"
              name={`warranty-${cartIndex}`}
              checked={!!warrantyEnabled}
              onChange={() => setWarrantyEnabled(productSKU, !warrantyEnabled, cartIndex)}
            />
            <label id={`warranty${cartIndex}`} htmlFor={`warrantyCheckBox${cartIndex}`} className="cart-esc-label">
              3 Year Furniture Protection Plan
            </label>
            &nbsp;
            <button
              type="button"
              onClick={() => setShowWarrantyModal(true)}
              className="info-link"
              aria-describedby={`warranty${cartIndex}`}
            >
              What's covered?
            </button>
          </div>
          <div className="price small-2 large-1 cart-item-price" data-testid={`${dtIdPrefix}protection-plan-price`}>
            <PriceAtom price={currencyFormatUS(warrantyPrice * productQuantity, true)} />
          </div>
        </div>
      </li>
    </ul>
  </>
)

AddonWarranty.propTypes = {
  cartIndex: PropTypes.number,
  dtIdPrefix: PropTypes.string,
  productQuantity: PropTypes.number,
  productSKU: PropTypes.string,
  setShowWarrantyModal: PropTypes.func,
  warrantyEnabled: PropTypes.bool,
  warrantyPrice: PropTypes.number,
}

export default AddonWarranty
