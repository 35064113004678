import React, { useState } from 'react'
import { styled, Button } from '@mui/material'
import { bool, string } from 'prop-types'

const BoldText = styled('span')({
  fontWeight: 700,
})

const ReadButton = styled(Button)({
  lineHeight: 'unset',
  marginTop: '-4px',
  paddingBottom: 0,
  paddingLeft: 0,
  paddingTop: 0,
  textDecoration: 'underline',
  textTransform: 'capitalize',
})

const StoreCartParagraph = ({ isMobile, storeName }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div style={{ marginTop: isMobile ? 15 : 10, padding: isMobile ? 0 : '20px 0' }}>
      <>
        The items selected during your recent visit to&nbsp;<BoldText>{storeName ?? 'ROOMS TO GO'}</BoldText>&nbsp;are
        in your store cart.&nbsp;
      </>
      {isMobile && !expanded ? (
        <>
          <ReadButton onClick={() => setExpanded(true)}>Read More</ReadButton>
        </>
      ) : (
        <>
          You can checkout online or modify your choices by shopping the website. Keep in mind that adding items might
          change your delivery date and/or delivery charge. Also, please note that since not all showroom merchandise is
          available online, if you delete an item from your store cart, it cannot be added back.&nbsp;
        </>
      )}
      {isMobile && expanded && <ReadButton onClick={() => setExpanded(false)}>Read Less</ReadButton>}
    </div>
  )
}

StoreCartParagraph.propTypes = {
  isMobile: bool,
  storeName: string,
}

export default StoreCartParagraph
