import PropTypes from 'prop-types'
import React, { useState } from 'react'
import BunkieModal from '@shared/modals/bunkie-modal'
import { getStateEligiblity } from '@helpers/extendedServiceContract'
import { getRegionZone } from '@helpers/geo-location'
import { isActiveAddon, getWarrantyDataByRegionZone } from '@helpers/product'
import '../../../assets/css/components/cart/cart-parts/cart-product-addon.sass'
import AddOnGroup from './add-on-group'

const AddOnSelection = ({ activeAddons, cartIndex, product, productQuantity, region, selections, warrantyEnabled }) => {
  const [showBunkieModal, setShowBunkieModal] = useState(false)
  const regionZone = getRegionZone()
  const warrantyData = product.warranty_pricing
    ? getWarrantyDataByRegionZone(product.warranty_pricing, regionZone)
    : product.warrantyPrice
  const isStateEligible = getStateEligiblity()

  const inactiveRequiredAddons =
    product?.addon_items?.filter(({ addon_required, sku }) => addon_required && !isActiveAddon(activeAddons, sku)) ?? []

  return (
    <div className="grid-x cart-addons medium-12">
      {inactiveRequiredAddons.map(requiredAddon => (
        <div key={`${requiredAddon.sku}-${cartIndex}-required-addon`} className="addon-required small-12 large-5">
          <p>
            {requiredAddon.title} ({requiredAddon.quantity}) <span className="addon-declined">Declined</span>
          </p>
          {requiredAddon.title.indexOf('Bunkie') > -1 && (
            <button
              type="button"
              gtm-category="bunkie-board-modal"
              gtm-action="click"
              gtm-label="cart"
              className="bunkie-board addon-learn-more"
              onClick={() => setShowBunkieModal(true)}
            >
              What is a bunkie board? &#9432;
            </button>
          )}
        </div>
      ))}
      <BunkieModal shouldShowModal={showBunkieModal} closeModal={() => setShowBunkieModal(false)} />
      <AddOnGroup
        activeAddons={activeAddons}
        cartIndex={cartIndex}
        isStateEligible={isStateEligible}
        product={product}
        productQuantity={productQuantity}
        region={region}
        selectionsTitle={selections?.title ?? ''}
        warrantyData={warrantyData}
        warrantyEnabled={warrantyEnabled}
      />
    </div>
  )
}

AddOnSelection.propTypes = {
  activeAddons: PropTypes.any,
  cartIndex: PropTypes.any,
  product: PropTypes.shape({
    addon_items: PropTypes.array,
    warrantyPrice: PropTypes.any,
    warranty_pricing: PropTypes.any,
  }),
  productQuantity: PropTypes.any,
  region: PropTypes.any,
  selections: PropTypes.any,
  warrantyEnabled: PropTypes.any,
}

export default AddOnSelection
