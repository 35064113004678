/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { bool, object } from 'prop-types'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'

import { verifyAndUpdateCart, cartUpdate, getPromoTargetSkus, loadCartFromOrder } from '@helpers/cart'
import { setupAnalytics } from '@helpers/google-tag-manager'
import { getLastParamOfPath } from '@helpers/string-helper'
import AllCartsWrapper from './all-carts-wrapper'
import StoreCartModal from './store-cart/store-cart-modal'

let apiCalled = false

export const Carts = ({ cart, isMobile, order, rtg_location, storeCartCreationData }) => {
  const [skusNotAvailable, setSkusNotAvailable] = useState([])
  const [discount, setDiscount] = useState(null)
  const [showPayments, setShowPayments] = useState({})
  const [storeCartIsLoading, setStoreCartIsLoading] = useState(true)
  const [showStoreCartModal, setShowStoreCartModal] = useState(!!storeCartCreationData?.didMoveCartItemsToFavorites)
  const promoTargetSkus = getPromoTargetSkus()

  const setApiCalled = called => {
    apiCalled = called
  }

  useEffect(() => {
    setupAnalytics({ pageData: { type: 'cart', title: 'Cart', path: '/cart' } })
    const lastParam = getLastParamOfPath()
    const pattern = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}')
    if (lastParam && pattern.test(lastParam)) {
      loadCartFromOrder(lastParam)
    }
  }, [])

  useEffect(() => {
    if (!_isEmpty(cart?.cartItems) || storeCartCreationData) {
      verifyAndUpdateCart(cart)
      cartUpdate({
        apiCalled,
        cart,
        checkProductAvailability: true,
        setApiCalled,
        setDiscount,
        setShowPayments,
        setSkusNotAvailable,
        setStoreCartIsLoading,
        skusNotAvailableIn: skusNotAvailable,
        storeCartCreationData,
        updateOrder: true,
        updateCartIfSwappable: true,
      })
    } else {
      setStoreCartIsLoading(false)
    }
  }, [JSON.stringify(cart), storeCartCreationData])

  const handleClose = () => setShowStoreCartModal(false)
  const handleFavorites = () => {
    handleClose()
    navigate('/favorites')
  }

  return (
    <>
      <AllCartsWrapper
        cart={cart}
        order={order}
        discount={discount}
        isMobile={isMobile}
        rtg_location={rtg_location}
        showPayPal={!!showPayments?.showPayPal}
        skusNotAvailable={skusNotAvailable}
        storeCartIsLoading={storeCartIsLoading}
        setStoreCartIsLoading={setStoreCartIsLoading}
        promoTargetSkus={promoTargetSkus}
        setSkusNotAvailable={setSkusNotAvailable}
      />
      {showStoreCartModal && order?.storeCart?.lineItems?.length && (
        <StoreCartModal
          associateName={order?.storeCart?.salesPersonInfo ?? ''}
          handleClose={handleClose}
          handleFavorites={handleFavorites}
          isOpen={showStoreCartModal}
          storeCartItems={order?.storeCart?.lineItems?.length}
        />
      )}
    </>
  )
}

Carts.propTypes = {
  cart: object,
  isMobile: bool,
  order: object,
  rtg_location: object,
  storeCartCreationData: object,
}

const mapStateToProps = ({ cart: myCart, checkout, global, location }) => {
  const { cart, storeCartCreationData } = myCart
  const { order } = checkout
  const { isMobile } = global
  const { rtg_location } = location

  return { cart, isMobile, order, rtg_location, storeCartCreationData }
}

export default connect(mapStateToProps, null)(Carts)
