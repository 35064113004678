import { styled, Stack } from '@mui/material'
import { fonts, colors } from '@constants/styles'

export const SplitDeliveryText = styled('p')({
  fontWeight: 600,
  fontSize: fonts.txtMedium,
  padding: '0.5rem',
  textAlign: 'center',
  width: '85%',
})

export const SplitDeliveryTextWrapper = styled(Stack)({
  background: '#ffffff',
  alignItems: 'center',
  padding: '0 0.5rem',
  border: `2px solid ${colors.darkerGreen}`,
})
