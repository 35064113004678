import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'
import { productPrice, isActiveAddon } from '@helpers/product'
import { fetchAndAddActiveAddon, removeActiveAddon, updateAddOnWarranty } from '@helpers/cart'
import { useTest } from '@hooks/useTest'
import AddonDetailsModal from '@shared/modals/addon-details-modal'
import WarrantyModalTemplate from '@shared/modals/warranty-modal'
import { ADJUSTABLE_BASE_STRING, HIDDEN_ADDONS_FOR_ADJUSTABLE_BASE } from '@constants/addons'
import PriceAtom from '../../../@rtg2022/components/atoms/Price/Price'
import AddonWarranty from './AddonWarranty'

const removeActiveAddonUpdate = (product, addon, cartIndex) => {
  removeActiveAddon(product.sku, addon.sku, cartIndex)
  updateAddOnWarranty(addon.sku, false, cartIndex)
}

const AddOnGroup = ({
  activeAddons,
  cartIndex,
  isStateEligible,
  product,
  productQuantity,
  region,
  selectionsTitle,
  warrantyData,
  warrantyEnabled,
}) => {
  const [selectedAddonDetailsForModal, setSelectedAddonDetailsForModal] = useState(null)
  const [showWarrantyModal, setShowWarrantyModal] = useState(false)
  const { generateTestId } = useTest()
  const dtIdPrefix = generateTestId('cart', '')
  const WarrantyModal = warrantyData ? WarrantyModalTemplate : null
  const isMattress = !!(product?.category?.match('bedding') && product?.breadcrumb?.breadcrumb_label?.match('Mattress'))

  // Prevent displaying frames addons if user selected an adjustable foundation for a mattress
  const optionalAddons = (selectionsTitle.includes(ADJUSTABLE_BASE_STRING)
    ? product?.addon_items.filter(addon => !HIDDEN_ADDONS_FOR_ADJUSTABLE_BASE.includes(addon.sku))
    : product?.addon_items ?? []
  ).filter(addon => !!addon?.catalog_availability[region])

  const handleAddonCheckboxClick = (isActive, addon) => {
    if (isActive) {
      removeActiveAddonUpdate(product, addon, cartIndex)
    } else {
      fetchAndAddActiveAddon(product, cartIndex, addon)
    }
  }

  return (
    <>
      {isStateEligible && (product?.warranty_pricing || product?.warrantyPrice) && warrantyData && (
        <AddonWarranty
          cartIndex={cartIndex}
          dtIdPrefix={dtIdPrefix}
          productQuantity={productQuantity}
          productSKU={product.sku}
          setShowWarrantyModal={setShowWarrantyModal}
          warrantyEnabled={warrantyEnabled}
          warrantyPrice={warrantyData?.price}
        />
      )}

      {optionalAddons?.length > 0 && (
        <>
          <div className={`addon-header add ${!isStateEligible ? 'hide' : ''}`} role="heading" aria-level="3">
            {isMattress ? 'Complete your Sleep' : 'Add-ons'}
          </div>
          <ul className="addon-group">
            {optionalAddons.map((addon, index) => {
              const isActive = isActiveAddon(activeAddons, addon?.sku)

              return (
                <li key={`${addon.sku}-addon-for-product-${product?.sku}`}>
                  <div className="grid-x">
                    <div className="small-10 large-11" data-testid={`${dtIdPrefix}addon-checkbox`}>
                      {addon.sell_individually && (
                        <>
                          <input
                            id={`labelledby_${index}${addon.sku}`}
                            type="checkbox"
                            className="addon-checkbox"
                            name={`${addon.title}-${cartIndex}`}
                            checked={isActive}
                            onChange={() => handleAddonCheckboxClick(isActive, addon)}
                          />
                          <label
                            style={{ pointerEvents: 'none' }}
                            htmlFor={`labelledby_${index}${addon.sku}`}
                            className="cart-esc-label"
                          >
                            {addon.title} ({addon.quantity})
                          </label>
                          &nbsp;
                          <button
                            type="button"
                            onClick={() => setSelectedAddonDetailsForModal(addon)}
                            className="info-link"
                            aria-describedby={`warranty${cartIndex}`}
                          >
                            See details
                          </button>
                        </>
                      )}
                      {!addon.sell_individually && (
                        <span>
                          {addon.title} ({addon.quantity}){' '}
                        </span>
                      )}
                    </div>
                    <div className="price small-2 large-1 cart-item-price" data-testid={`${dtIdPrefix}addon-price`}>
                      <PriceAtom
                        price={currencyFormatUS(productPrice(addon) * addon.quantity * productQuantity, true)}
                      />
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </>
      )}
      {WarrantyModal && (
        <WarrantyModal
          shouldShowModal={showWarrantyModal}
          closeModal={() => setShowWarrantyModal(false)}
          region={warrantyData.provider_name}
        />
      )}
      {selectedAddonDetailsForModal && (
        <AddonDetailsModal
          shouldShowModal={!!selectedAddonDetailsForModal}
          addon={selectedAddonDetailsForModal}
          product={product}
          cartIndex={cartIndex}
          region={region}
          onCloseModal={() => setSelectedAddonDetailsForModal(null)}
        />
      )}
    </>
  )
}

AddOnGroup.propTypes = {
  activeAddons: PropTypes.array,
  cartIndex: PropTypes.number,
  isStateEligible: PropTypes.bool,
  product: PropTypes.object,
  productQuantity: PropTypes.number,
  region: PropTypes.string,
  selectionsTitle: PropTypes.string,
  warrantyData: PropTypes.object,
  warrantyEnabled: PropTypes.bool,
}

export default AddOnGroup
