import React from 'react'
import styled from 'styled-components'
import { fonts, colors } from '@constants/styles'

const NoImageSvg = styled(({ className }) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <g>
          <path d="M7,11v2h10v-2c0-1.86,1.28-3.41,3-3.86V6c0-1.65-1.35-3-3-3H7C5.35,3,4,4.35,4,6v1.14C5.72,7.59,7,9.14,7,11z" />
          <path d="M21,9c-1.1,0-2,0.9-2,2v4H5v-4c0-1.1-0.9-2-2-2s-2,0.9-2,2v5c0,1.65,1.35,3,3,3v1c0,0.55,0.45,1,1,1c0.55,0,1-0.45,1-1v-1 h12v1c0,0.55,0.45,1,1,1c0.55,0,1-0.45,1-1v-1c1.65,0,3-1.35,3-3v-5C23,9.9,22.1,9,21,9z" />
        </g>
      </g>
    </svg>
    <span>Image Unavailable</span>
  </div>
))`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100px;
  height: 60px;
  background-color: ${colors.lightGray};
  border: 1px solid ${colors.lightGrey};
  border-radius: 5px;
  > svg {
    width: 20px;
    fill: ${colors.mediumGrey};
  }
  > span {
    font-size: ${fonts.txtMini};
  }
  @media only screen and (max-width: 1024px) {
    margin: 0 auto;
    width: 130px;
  }
`

export default NoImageSvg
