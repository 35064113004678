import PropTypes from 'prop-types'
import React from 'react'
import InfoModal from '@shared/info-modal'
import StrapiHtml from '@templates/strapi-cms/content-types/HTML'
import { graphql, StaticQuery } from 'gatsby'

const BunkieModal = ({ shouldShowModal, closeModal }) => (
  <InfoModal
    label="What is a Bunkie Board?"
    mdlClass="rtg-bunkie-board-modal"
    shouldShowModal={shouldShowModal}
    closeModal={closeModal}
  >
    <StaticQuery
      query={graphql`
        query SharedBunkieModalQuery {
          strapiHtml(Title: { eq: "HTML - Bunkie Board Modal" }) {
            ...StrapiHtmlFragment
          }
        }
      `}
      render={data => <StrapiHtml data={data.strapiHtml} />}
    />
  </InfoModal>
)

BunkieModal.propTypes = {
  closeModal: PropTypes.any,
  shouldShowModal: PropTypes.any,
}

export default BunkieModal
