import React from 'react'
import { object } from 'prop-types'
import { styled } from '@mui/material'
import { isEmpty } from 'lodash'

const StyledContainer = styled('div')({
  width: '100%',
  marginTop: '10px',
})

const StyledTextContainer = styled('div')({
  display: 'flex',
  fontSize: '14px',
  lineHeight: '19px',
  '&:not(:first-of-type)': {
    paddingTop: '1rem',
  },
})

const StyledText = styled('div')({
  fontWeight: 'bold',
  textTransform: 'capitalize',
  flex: 1,
})

const MattressSelections = ({
  cartItem: {
    product: { size },
    selections,
  },
}) => (
  <StyledContainer>
    {size && (
      <StyledTextContainer>
        Size:&nbsp;<StyledText>{size}</StyledText>
      </StyledTextContainer>
    )}
    {!isEmpty(selections) && (
      <StyledTextContainer>
        Foundation:&nbsp;<StyledText>{selections.title}</StyledText>
      </StyledTextContainer>
    )}
  </StyledContainer>
)

MattressSelections.propTypes = {
  cartItem: object,
}

export default MattressSelections
