import React from 'react'
import { arrayOf, bool, object } from 'prop-types'
import { Stack } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Price from '../../../../@rtg2022/components/atoms/Price/Price'
import { ActiveAddonsDiv, InfoDiv, Plan, ProductListDiv, Quantity, Sku, Title, Img } from './styles'
import NoImageSvg from '../no-image-svg'

const ProductListItems = ({ isMobile, isUnavailable, productListItems }) => {
  if (!isMobile) {
    return (
      <ProductListDiv>
        {productListItems.map(({ imageUrl, key, quantity, sku, title, warrantyEnabled, warrantyPrice }) => (
          <ActiveAddonsDiv key={key}>
            {imageUrl ? <Img src={imageUrl} /> : <NoImageSvg />}
            <InfoDiv>
              <Title>
                {title} {quantity > 1 ? <Quantity>{`(x${quantity})`}</Quantity> : null}
              </Title>
              <Sku>SKU: {sku}</Sku>
              {warrantyEnabled && (
                <Plan>
                  {'+ Furniture Protection Plan '}
                  {warrantyPrice && <Price price={warrantyPrice} />}
                </Plan>
              )}
              {isUnavailable && (
                <Stack mt={1} direction="row" alignItems="center" spacing={0.75}>
                  <WarningAmberIcon fontSize="small" htmlColor="#E11F21" />
                  <span style={{ color: '#E11F21', fontSize: 14, fontWeight: 500 }}>Not available in your region</span>
                </Stack>
              )}
            </InfoDiv>
          </ActiveAddonsDiv>
        ))}
      </ProductListDiv>
    )
  }

  const hasChildren = productListItems.length > 1
  const firstItem = productListItems[0]

  return (
    <>
      <ProductListDiv>
        <ActiveAddonsDiv>
          {isUnavailable && (
            <Stack mt={1} direction="row" alignItems="flex-start" justifyContent="center" spacing={0.75}>
              <WarningAmberIcon fontSize="small" htmlColor="#E11F21" />
              <span style={{ color: '#E11F21', fontSize: 14, fontWeight: 500 }}>Not available in your region</span>
            </Stack>
          )}
          {firstItem.imageUrl ? <Img src={firstItem.imageUrl} /> : <NoImageSvg />}
          <InfoDiv>
            <Title>
              {firstItem.title} {firstItem.quantity > 1 ? <Quantity>{`(x${firstItem.quantity})`}</Quantity> : null}
            </Title>
            <Sku>SKU: {firstItem.sku}</Sku>
            {firstItem.warrantyEnabled && (
              <Plan>
                {'+ Furniture Protection Plan '}
                {firstItem.warrantyPrice && <Price price={firstItem.warrantyPrice} />}
              </Plan>
            )}
          </InfoDiv>
        </ActiveAddonsDiv>
        {hasChildren &&
          productListItems.slice(1).map(({ imageUrl, key, quantity, sku, title, warrantyEnabled, warrantyPrice }) => (
            <ActiveAddonsDiv key={key}>
              {imageUrl ? <Img src={imageUrl} /> : <NoImageSvg />}
              <InfoDiv>
                <Title>
                  {title} {quantity > 1 ? <Quantity>{`(x${quantity})`}</Quantity> : null}
                </Title>
                <Sku>SKU: {sku}</Sku>
                {warrantyEnabled && (
                  <Plan>
                    {'+ Furniture Protection Plan '}
                    {warrantyPrice && <Price price={warrantyPrice} />}
                  </Plan>
                )}
              </InfoDiv>
            </ActiveAddonsDiv>
          ))}
      </ProductListDiv>
    </>
  )
}

ProductListItems.propTypes = {
  isMobile: bool,
  isUnavailable: bool,
  productListItems: arrayOf(object),
}

export default ProductListItems
