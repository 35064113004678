import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { getURLParam } from '@helpers/general'
import Layout from '../components/layout'
import Cart from '../components/cart'
import ConfiguratorImporter from '../components/cart/configuratorImporter'

const CartPage = ({ props }) => {
  const cartIdFromUrl = getURLParam('cartId') ?? ''
  return (
    <Layout {...props} cartPage>
      <Helmet title="Shopping Cart - Rooms To Go" meta={[{ name: 'robots', content: 'noindex, nofollow' }]} />
      {cartIdFromUrl ? <ConfiguratorImporter cartIdFromUrl={cartIdFromUrl} /> : <Cart />}
    </Layout>
  )
}

CartPage.propTypes = {
  props: PropTypes.any,
}

export default CartPage
