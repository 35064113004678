// dependencies
import React, { useMemo } from 'react'
import { any, bool, number, object, shape, string } from 'prop-types'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Grid, IconButton, Stack, Typography, styled } from '@mui/material'

// helpers
import { getRegionZone } from '@helpers/geo-location'
import { productPrice, enhanceStockMessage, getProductDisplayImage } from '@helpers/product'
import { productUrl } from '@helpers/route'
import { currencyFormatUS } from '@helpers/string-helper'
import {
  incrementCartProductQuantity,
  decrementCartProductQuantity,
  getProductPriceWithSelections,
  removeFromCart,
} from '@helpers/cart'

// hooks
import { useIsFavorited, useUpdateFavorites } from '@hooks/auth'

// components
import RoomItemList from '@shared/room-item-list'
import Price from '../../../@rtg2022/components/atoms/Price/Price'
import ProductPrice from '../../../@rtg2022/components/atoms/Price/ProductPrice'
import Quantity from '../../../@rtg2022/components/atoms/Quantity'
import AddonSelection from './add-on-selection'
import MattressSelections from './mattress-selections'
import RTGLink from '../../shared/link'
import RemoveFromCart from './remove-from-cart'
import ProductLocation from '../../product/product-parts/product-location'
import CouponTag from '../../../@rtg2022/components/atoms/CouponTag/CouponTag'
import PriceAtom from '../../../@rtg2022/components/atoms/ProductPrice/PriceAtom'

// hooks
import { useProduct } from '../../product/hooks'

import '../../../assets/css/components/cart/cart-parts/cart-product.sass'

const StockAvailabilityMessage = styled('div')({
  fontStyle: 'italic',
  fontSize: '15px',
  padding: '8px 0',
})

const CustomerAssembly = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginTop: '40px',
  fontStyle: 'italic',
  fontSize: '15px',
  '& b': {
    fontWeight: 700,
  },
}))

const ProductPriceContainer = styled(Grid)({
  '& > div > div > div': {
    justifyContent: 'flex-end',
    '& > div > div': {
      textAlign: 'right',
    },
  },
})

const StyledBonusBuyLabel = styled('div')({
  backgroundColor: '#E11F21',
  color: 'white',
  borderRadius: '4px',
  padding: '5px 10px',
  fontSize: '9px',
  height: '22px',
  width: '135px',
  marginBottom: '10px',
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  letterSpacing: '1px',
  lineHeight: '1',
  textAlign: 'center',
})

const StyledProductPrice = styled(Price)(({ isMobile, theme }) => ({
  fontSize: !isMobile ? '18px' : '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0px',
  marginTop: '18px',
  color: theme.palette.error.border,
}))

const getLimit = (cartItem, cartItems) => {
  if (cartItem.sku !== '83333333') {
    return 10
  }
  const totalAmount = cartItems.reduce(
    (total, item) => (item.sku === '83333333' ? total + item.price * item.quantity : total),
    0,
  )

  if (totalAmount + cartItem.price > 2000) {
    return 0
  }
  const totalQuantity = cartItems.reduce((total, item) => (item.sku === '83333333' ? total + item.quantity : total), 0)
  return Math.min(10 - totalQuantity + cartItem.quantity, 10)
}

const CartProduct = ({
  bonusBuyDiscount,
  couponApplied,
  cartItem,
  index,
  isMobile,
  productCount,
  region,
  unavailableItem,
  warrantyEnabled,
  lineItem,
  showFinanceMessage,
}) => {
  const { cart } = useSelector(state => state.cart)
  const { activeAddons, product, quantity, additionalOption } = cartItem
  const { isConfigurable: isConfiguratorItem = false, stockMessage } = product
  const { isStrikeThrough, route, price, showFinance, financeAmount, financeNumMonths, promoMessageHTML } = useProduct({
    product,
    foundationSelection: cartItem?.selections,
  })
  const isFavorited = useIsFavorited(product.sku)

  let items_in_room = (product?.items_in_room?.[`${getRegionZone()?.region}`] || product?.items_in_room) ?? []

  if (isConfiguratorItem && items_in_room.length === 0) {
    items_in_room = product?.childItems ?? []
  }

  const fullPrice = lineItem?.additionalOption
    ? getProductPriceWithSelections(
        lineItem.unitPrice,
        quantity,
        cartItem,
        lineItem?.additionalOptionPrice?.savings || 0,
      )
    : getProductPriceWithSelections(price, quantity, cartItem)

  const handleUpdateFavorites = useUpdateFavorites(product, index)
  const canFavorite = !isFavorited && product.sku !== '83333333' && !isConfiguratorItem

  const handleMoveToFavoritesClick = () => {
    handleUpdateFavorites()
    removeFromCart(product, index, cartItem.uid)
  }

  const memoizedLimit = useMemo(() => getLimit(cartItem, cart.cartItems), [cartItem, cart.cartItems])

  return (
    <div
      key={`${product.sku}-${index}`}
      className={classNames('cart-product cell small-12', {
        separator: index !== productCount - 1,
      })}
    >
      {unavailableItem?.reason === 'region' && (
        <div id={`region_${product.sku}`} className="not-available-msg">
          Not Available in your region
        </div>
      )}
      {unavailableItem?.reason === 'stock' && (
        <div id={`stock_${product.sku}`} className="not-available-msg">
          Out of Stock
        </div>
      )}
      <div className="product-container cart-image-title-container grid-x">
        <div className="product-image-container small-3 large-4">
          {isConfiguratorItem ? (
            <>
              {product?.primary_image && (
                <>
                  {isMobile ? (
                    <img src={getProductDisplayImage(product)} alt={product?.displayNamePDP || product.title} />
                  ) : (
                    <Stack direction="row" justifyContent="center">
                      <img
                        src={getProductDisplayImage(product)}
                        alt={product?.displayNamePDP || product.title}
                        style={{ maxWidth: '288px', maxHeight: '14rem' }}
                      />
                    </Stack>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {product.primary_image && (
                <RTGLink
                  data={{
                    slug: productUrl(route),
                    altDesc: product?.displayNamePDP || product.title,
                  }}
                  noAriaLabel
                  aria-hidden
                  tabIndex="-1"
                  disabled={product.category === 'gift-card'}
                >
                  <img
                    src={`${getProductDisplayImage(product)}${product.sku !== '83333333' ? '&h=150' : ''}`}
                    alt={product?.displayNamePDP || product.title}
                    className="product-image"
                  />
                </RTGLink>
              )}
            </>
          )}
          {!isMobile && (
            <Grid container direction="column" className="hide-large-down">
              <Grid item>
                <div className="small-12 dropdown-remove">
                  <RemoveFromCart ariaId={`ariaId_${index}`} product={product} index={index} uid={cartItem.uid} />
                  {canFavorite && (
                    <>
                      <div className="small-separator">|</div>
                      <button type="button" className="move-to-favorites-btn" onClick={handleMoveToFavoritesClick}>
                        Move to Favorites
                      </button>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </div>
        <div className="product-info small-6 large-4">
          {bonusBuyDiscount && <StyledBonusBuyLabel>Bonus Buy Included</StyledBonusBuyLabel>}
          <div role="heading" aria-level="2">
            {isConfiguratorItem ? (
              <p className="product-title" style={{ fontWeight: 700 }}>
                {product?.title ?? ''}
              </p>
            ) : (
              <RTGLink
                data={{
                  slug: productUrl(route),
                  altDesc: product?.displayNamePDP || product.title,
                }}
                noAriaLabel
                className={classNames('product-title', {
                  'not-available': unavailableItem,
                })}
                aria-describedby={
                  unavailableItem?.reason === 'region' ? `region_${product.sku}` : `stock_${product.sku}`
                }
                disabled={product?.addon_required || product.category === 'gift-card'}
              >
                <span
                  id={`ariaId_${index}`}
                  dangerouslySetInnerHTML={{ __html: product?.displayNamePDP || product.title }}
                />
                <div className="cart-shipping">
                  <ProductLocation
                    cart
                    product={product}
                    stockMessage={stockMessage}
                    doNotCallGetStockMessage
                    hideStockMessage
                  />
                </div>
              </RTGLink>
            )}
          </div>
          {additionalOption ? <h5 style={{ padding: '5px 0px' }}>Added to Package</h5> : null}
          <p className="sku-text">SKU: {product.sku}</p>
          {couponApplied && (
            <CouponTag
              source="cart"
              message={
                cartItem.promotion?.promotionSavings?.description ||
                cartItem.product?.promotions?.[`${getRegionZone()?.region}_${getRegionZone()?.zone}`]?.offer_description
              }
              PriceComponent={<PriceAtom price={currencyFormatUS(fullPrice)} />}
            />
          )}
          {!isConfiguratorItem && (
            <Stack direction="row" alignItems="center" sx={{ margin: '16px 0' }}>
              <span className="quantity-label">Quantity:</span>
              <div className="quantity-element">
                <Quantity
                  limit={memoizedLimit}
                  initialQuantity={quantity}
                  onDecrement={newQuantity =>
                    decrementCartProductQuantity(newQuantity, cart?.cartItems, product.sku, activeAddons, cartItem.uid)
                  }
                  onIncrement={newQuantity =>
                    incrementCartProductQuantity(newQuantity, cart?.cartItems, product.sku, activeAddons, cartItem.uid)
                  }
                />
              </div>
            </Stack>
          )}
          {isMobile && (
            <Grid container direction="column" className="hide-large-up">
              <Grid item>
                <div className="small-12 dropdown-remove">
                  <RemoveFromCart ariaId={`ariaId_${index}`} product={product} index={index} uid={cartItem.uid} />
                  {canFavorite && (
                    <>
                      <div className="small-separator">|</div>
                      <button type="button" className="move-to-favorites-btn" onClick={handleMoveToFavoritesClick}>
                        Move to Favorites
                      </button>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
          <StockAvailabilityMessage className="stock-message">
            {Array.isArray(stockMessage) ? (
              stockMessage?.map(stockItem => (
                <stockItem.Component key={stockItem.message}>{stockItem?.message}</stockItem.Component>
              ))
            ) : (
              <div dangerouslySetInnerHTML={{ __html: enhanceStockMessage(stockMessage) }} />
            )}
          </StockAvailabilityMessage>
          {product?.customer_assembly_required && (
            <CustomerAssembly>
              <b>Note:</b> Customer Assembly Required.
            </CustomerAssembly>
          )}
          {!isMobile && (
            <div className="double-size-container small-12 hide-large-down">
              {cartItem?.selections && <MattressSelections cartItem={cartItem} />}
              {product && (product.addon_items || product.warranty_pricing || product.warrantyPrice) && (
                <AddonSelection
                  product={product}
                  productQuantity={quantity}
                  activeAddons={activeAddons}
                  cartIndex={index}
                  warrantyEnabled={warrantyEnabled}
                  region={region}
                  selections={cartItem?.selections}
                />
              )}

              {items_in_room && items_in_room.length > 0 && <RoomItemList items={items_in_room} />}

              <div className="mobile cell">
                <div className="small-12 medium-1">
                  <div className="cart-shipping">
                    <ProductLocation cart product={product} stockMessage={stockMessage} doNotCallGetStockMessage />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="product-price small-3 large-4">
          <ProductPriceContainer container justifyContent="flex-end">
            <Grid item width="100%">
              <ProductPrice
                financeAmount={currencyFormatUS(Math.ceil(financeAmount))}
                monthly={financeNumMonths}
                price={currencyFormatUS(fullPrice)}
                sale={isStrikeThrough || product?.on_sale}
                strikeThrough={isStrikeThrough && !!product?.list_price}
                strikeThroughPrice={currencyFormatUS(productPrice(product, true) * quantity)}
                // showFinancing={showFinance && showFinanceMessage}
                showFinancing={false}
                promoMessageHTML={promoMessageHTML}
                cleanVariant
              />
            </Grid>

            {bonusBuyDiscount && (
              <Grid item width="100%" justifyContent="right" style={{ textAlign: 'right' }}>
                <StyledProductPrice isMobile={isMobile} price={currencyFormatUS(bonusBuyDiscount)} negative />
              </Grid>
            )}
          </ProductPriceContainer>
        </div>
        {isMobile && (
          <>
            <div className="cart-product-divider hide-large-up" />
            <div className="small-12 hide-large-up">
              {cartItem?.selections && <MattressSelections cartItem={cartItem} />}
              {product && (product.addon_items || product.warranty_pricing || product.warrantyPrice) && (
                <AddonSelection
                  product={product}
                  productQuantity={quantity}
                  activeAddons={activeAddons}
                  cartIndex={index}
                  warrantyEnabled={warrantyEnabled}
                  region={region}
                  selections={cartItem?.selections}
                />
              )}

              {items_in_room && items_in_room.length > 0 && <RoomItemList items={items_in_room} />}

              <div className="mobile cell">
                <div className="small-12 medium-1">
                  <div className="cart-shipping">
                    <ProductLocation cart product={product} stockMessage={stockMessage} doNotCallGetStockMessage />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

CartProduct.propTypes = {
  bonusBuyDiscount: any,
  couponApplied: bool,
  cartItem: object,
  index: number,
  isMobile: bool,
  productCount: number.isRequired,
  region: string,
  unavailableItem: shape({
    sku: string.isRequired,
    reason: string.isRequired,
  }),
  warrantyEnabled: bool,
  lineItem: object,
  showFinanceMessage: bool,
}

export default React.memo(CartProduct)
