import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { removeFromCart } from '@helpers/cart'
import '../../../assets/css/components/cart/cart-parts/remove-from-cart.sass'

const RemoveFromCart = ({ product, index, headerCart, ariaId, uid }) => (
  <button
    type="button"
    value="Remove From Cart"
    aria-describedby={ariaId}
    className={classNames({ 'remove-btn': !headerCart, close: headerCart })}
    onClick={() => removeFromCart(product, index, uid)}
  >
    {!headerCart ? 'Remove ' : ''}
    <span className="hide508">{headerCart ? 'Remove' : ''} from cart</span>
  </button>
)

export default RemoveFromCart

RemoveFromCart.propTypes = {
  product: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  headerCart: PropTypes.bool,
  ariaId: PropTypes.string,
  uid: PropTypes.string,
}
