import React, { useCallback } from 'react'
import { bool, func, number, string } from 'prop-types'
import { styled, Box, Button, Stack, Typography } from '@mui/material'
import { setEquickDecision } from '@helpers/checkout/payment-section/rtg-finance'
import { reloadStoreCart } from '@helpers/store-cart'

const StyledRefreshContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    marginBottom: 15,
  },
}))

const StyledRefreshButton = styled(Button)(({ theme }) => ({
  margin: '0 0 0 15px',
  minWidth: 147,
  fontSize: 15,
  fontWeight: 600,
  '& > *:first-of-type': {
    marginRight: 5,
    height: '1.2rem',
    width: '1.2rem',
  },
  [theme.breakpoints.down('md')]: {
    margin: '0 15px 0 0',
    minWidth: 167,
  },
}))

const LargeHeader = ({ amount, isMobile, setIsLoading, text, storeCartId }) => {
  const handleReloadStoreCart = useCallback(async () => {
    try {
      setIsLoading(true)
      await reloadStoreCart()
    } catch {
      setEquickDecision()
      console.error('Error updating Store Cart')
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading])

  const displayRefreshCart = !!setIsLoading

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'flex-start', md: 'space-between' }}
      justifyContent={{ xs: 'unset', md: 'space-between' }}
      mt={3}
      pb={{ xs: displayRefreshCart ? 0 : 2, md: 2 }}
      spacing={2}
    >
      <Stack direction="row" alignItems="baseline" spacing={1.25}>
        <Typography
          variant="h1"
          sx={isMobile ? { fontSize: '1.5rem' } : { fontSize: '2rem', textTransform: 'uppercase' }}
        >
          {text}
        </Typography>
        {amount ? (
          <Typography sx={{ fontSize: { xs: '18px', md: '24px' }, fontWeight: 500 }}>
            {`(${amount} ${amount === 1 ? 'item' : 'items'})`}
          </Typography>
        ) : null}
        {storeCartId ? <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>#{storeCartId}</Typography> : null}
      </Stack>
      {displayRefreshCart && (
        <StyledRefreshContainer>
          <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            Refresh to see the latest Store Cart items
          </Typography>
          <StyledRefreshButton color="primary" variant="outlined" onClick={handleReloadStoreCart}>
            UPDATE CART
          </StyledRefreshButton>
        </StyledRefreshContainer>
      )}
    </Stack>
  )
}

LargeHeader.propTypes = {
  amount: number,
  isMobile: bool,
  setIsLoading: func,
  text: string.isRequired,
  storeCartId: string.isRequired,
}

export default React.memo(LargeHeader)
