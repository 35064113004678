/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { string } from 'prop-types'
import { navigate } from 'gatsby'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { addToCart, checkConfiguratorImport } from '@helpers/cart'
import { productPrice } from '@helpers/product'
import { getM1ConfiguratorCartItem } from '@services/checkout'

const Title = styled(Typography)(() => ({
  fontSize: '25px',
  fontWeight: 500,
  color: '#07263B',
  textTransform: 'none',
}))

const Message = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  color: '#07263B',
  textTransform: 'none',
}))

const uuidPattern = new RegExp('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}')

export const ConfiguratorImporter = ({ cartIdFromUrl }) => {
  const [open, setOpen] = useState(true)
  // do not import Configurator Cart if an existing cart item has the same cartIdFromUrl (ie., user clicked browser back button)
  const [isImported, setIsImported] = useState(true)
  const [fetchFailed, setFetchFailed] = useState(false)

  // confirm cartId is a valid uuid
  const isValidId = uuidPattern.test(cartIdFromUrl)

  const canImport = useMemo(() => isValidId && !isImported && !fetchFailed, [isValidId, isImported, fetchFailed])

  const getConfiguratorCart = async cartId => {
    try {
      const response = await getM1ConfiguratorCartItem(cartId)
      const { lineItems = [] } = response

      if (Array.isArray(lineItems) && lineItems.length > 0) {
        lineItems.forEach(product => {
          const price = productPrice(product)
          addToCart({
            product: { ...product, configuratorCartId: cartId },
            price,
            activeAddons: null,
            warrantyEnabled: false,
          })
        })
      }

      handleClose()
    } catch (error) {
      console.error('UNABLE TO FETCH CONFIGURATOR CART: ', error)
      setFetchFailed(true)
    }
  }

  useEffect(() => {
    setIsImported(checkConfiguratorImport(cartIdFromUrl))
  }, [])

  useEffect(() => {
    if (canImport) getConfiguratorCart(cartIdFromUrl)
  }, [canImport])

  const handleClose = () => {
    setOpen(false)
    navigate('/cart') // re-route to /cart with cartId query string parameter removed from URL
  }

  return (
    <>
      <Stack
        mt={13}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'flex-start', sm: 'center' }}
        sx={{ height: '600px', width: '100%' }}
      >
        <h2 style={{ textAlign: 'center' }}>...IMPORTING CONFIGURED ITEM TO YOUR CART</h2>
      </Stack>
      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { maxWidth: '600px' } }}>
        {!canImport && (
          <DialogTitle sx={{ padding: '12px 20px 20px 20px' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Title>
                Product <u>Not</u> Added to Cart
              </Title>
              {(isValidId || fetchFailed) && (
                <IconButton
                  onClick={handleClose}
                  edge="end"
                  size="large"
                  color="primary"
                  sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
            <Divider />
          </DialogTitle>
        )}
        <DialogContent>
          {canImport ? (
            <h2 style={{ textAlign: 'center' }}>...IMPORTING CONFIGURED ITEM TO YOUR CART</h2>
          ) : (
            <>
              {fetchFailed ? (
                <Message>
                  An error occurred while importing a configured item.
                  <br />
                  Please try again.
                </Message>
              ) : (
                <>
                  {isValidId ? (
                    <Message>
                      Only 1 customized item can be processed at a time.
                      <br />
                      <br />
                      To add a new customized item, first remove the existing custom item from the cart.
                    </Message>
                  ) : (
                    <Message>
                      Invalid Cart ID.
                      <br />
                    </Message>
                  )}
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: '0 20px 20px 20px' }}>
          {canImport ? (
            <Stack direction="row" justifyContent="center" sx={{ width: '100%' }}>
              <CircularProgress />
            </Stack>
          ) : (
            <Button fullWidth variant="contained" onClick={handleClose}>
              Continue to cart
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

ConfiguratorImporter.propTypes = {
  cartIdFromUrl: string,
}

export default ConfiguratorImporter
