import React from 'react'
import { bool, func, object } from 'prop-types'
import { Dialog, styled } from '@mui/material'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  lineHeight: 1.25,
  '.close-modal': {
    position: 'absolute',
    top: '-2em',
    right: '-1.75em',
    background: theme.palette.primary.dark,
    width: '3rem',
    height: '3rem',
    borderRadius: '50%',
  },
  '.close': {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: '2.5rem',
    height: '2.5rem',
    margin: 'auto',
    backgroundSize: '100% !important',
  },
  '.title': {
    marginBottom: '2rem',
    textAlign: 'center',
    h2: {
      display: 'inline',
      fontWeight: 400,
      margin: '0 auto',
    },
  },
  '.modal-content': {
    backgroundColor: theme.palette.common.white,
    border: `solid ${theme.palette.grey[300]} 0.4rem`,
    width: '70%',
    position: 'fixed',
    right: 'auto',
    bottom: 'auto',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '45rem',
    maxWidth: '45rem',
    padding: '1rem',
    '@media only screen and (max-width: 1024px)': {
      minWidth: '10rem',
      width: '90%',
      maxHeight: '90%',
    },
  },
  '.desc-list': {
    marginLeft: '5%',
    marginRight: '5%',
  },
  '.promo-desc': {
    textAlign: 'left',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
  },
  '.promo-amount': {
    textAlign: 'right',
    fontSize: theme.typography.body1.fontSize,
    color: 'rgb(255, 0, 0)',
  },
}))

const PromotionsModal = ({ closeModal, modalOpen, promotions = {} }) => (
  <StyledDialog
    // className="promo-modal"
    open={modalOpen}
    onClose={closeModal}
  >
    <div className="modal-content">
      <div className="title">
        <h2>Offers Applied</h2>
      </div>
      <ul className="desc-list">
        {promotions?.promotions?.map((promo, index) => {
          const key = `promo_${index}`
          return (
            <li key={key} className="desc grid-x">
              <span className="small-12 medium-10 promo-desc">{promo.description}</span>
              <span className="small-12 medium-2 promo-amount">-${promo.amount}</span>
            </li>
          )
        })}
        <li className="desc grid-x">
          <span className="small-12 medium-10 promo-desc">Total Amount Saved:</span>{' '}
          <span className="small-12 medium-2 promo-amount">-${promotions?.totalSavings}</span>
        </li>
      </ul>
      <button type="button" className="close-modal" tabIndex="0" value="Close" aria-label="Close" onClick={closeModal}>
        <img
          className="icon close"
          alt="close icon"
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
        />
      </button>
    </div>
  </StyledDialog>
)

PromotionsModal.propTypes = {
  closeModal: func,
  modalOpen: bool,
  promotions: object,
}

export default PromotionsModal
