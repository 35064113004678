import React from 'react'
import PropTypes from 'prop-types'
import { Box, styled } from '@mui/material'
import { addDashesToNumber } from '@helpers/number-helper'

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  padding: '10px 0',
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.dark,
  fontSize: '18px',
  fontWeight: 500,
}))

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '20px',
  margin: '0 auto',
  color: theme.palette.secondary.dark,
}))

const Person = styled('span')({
  fontWeight: 700,
})

const Address = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0 5px',
})

const Underline = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  fontWeight: 400,
  textDecoration: 'underline',
}))

const StoreName = styled('span')({
  fontWeight: 600,
})

const StoreContactInfo = ({ storeInfo, className, hideHeader }) => {
  const { salesPersonInfo, storeAddress, storeName, storePhoneNumber } = storeInfo

  return (
    <div className={className}>
      {!hideHeader && <Header>Store Contact Information</Header>}
      <Content>
        <span>
          Store associate <Person>{salesPersonInfo}</Person> assisted you with your store cart experience
        </span>
        <Address>
          <StoreName>{storeName}</StoreName>
          <Underline>{`${storeAddress?.address1} ${storeAddress?.address2 ?? ''}`}</Underline>
          <Underline>
            {storeAddress?.city}, {storeAddress?.state} {storeAddress?.zip}
          </Underline>
        </Address>
        <Underline>{addDashesToNumber(storePhoneNumber.replaceAll(/[^0-9]+/g, ''))}</Underline>
      </Content>
    </div>
  )
}

StoreContactInfo.propTypes = {
  storeInfo: PropTypes.object,
  className: PropTypes.string,
  hideHeader: PropTypes.bool,
}

export default StoreContactInfo
