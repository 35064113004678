import React from 'react'
import { Stack, Typography, styled } from '@mui/material'
import RTGLink from '../../shared/link'

const Wrapper = styled(Stack)(() => ({
  fontSize: '24px',
  margin: '24px 0',
  '& a': {
    fontWeight: 'bold',
    width: '15em',
  },
}))

const CartEmpty = () => (
  <Wrapper id="empty-cart-title" spacing={2} alignItems={{ xs: 'center', md: 'flex-start' }}>
    <Typography variant="body">There are no items in your cart.</Typography>
    <RTGLink
      id="empty-cart-continue"
      className="blue-action-btn"
      aria-describedby="empty-cart-title"
      data={{
        slug: '/',
        category: 'cart-empty',
        action: 'click',
        label: 'continue shopping',
      }}
    >
      Continue Shopping
    </RTGLink>
  </Wrapper>
)

export default CartEmpty
