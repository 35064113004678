import React from 'react'
import { bool, func, number, string } from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const StoreCartModal = ({ associateName, handleClose, handleFavorites, isOpen, storeCartItems }) => (
  <Dialog
    onClose={handleClose}
    open={isOpen}
    id="store-cart-modal"
    tabIndex="0"
    fullWidth
    maxWidth="xs"
    PaperProps={{ style: { margin: '12px', width: 'calc(100% - 24px)' } }}
  >
    <DialogTitle id="store-cart-modal-title" component="div" sx={{ p: '0 0 12px 24px' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h2"
          sx={{ fontSize: 19, fontWeight: 600, textTransform: 'capitalize', paddingTop: '12px' }}
        >
          Items Added to Cart
        </Typography>
        <IconButton title="Close Store Cart Modal" size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>

    <DialogContent style={{ paddingTop: '8px' }}>
      <Typography sx={{ fontSize: '18px' }}>
        <span style={{ color: '#00854a', fontWeight: 600 }}>{`${storeCartItems} Item${
          storeCartItems > 1 ? 's' : ''
        }`}</span>
        {` added to your cart by ${associateName ? 'store associate ' : 'your Rooms To Go Store Associate.'}`}
        {associateName && <span style={{ textTransform: 'capitalize' }}>{associateName.toLowerCase()}.</span>}
      </Typography>
      <Stack direction="row" spacing={1} mt={2}>
        <InfoOutlinedIcon sx={{ color: '#808080', marginTop: '4px' }} />
        <Typography sx={{ fontSize: '16px' }}>
          Items previously in your online shopping cart have been moved to your Favorites.
        </Typography>
      </Stack>
    </DialogContent>

    <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
      <Stack direction={{ xs: 'column', md: 'row' }} sx={{ width: '100%' }} spacing={1}>
        <Button fullWidth variant="outlined" onClick={handleFavorites} startIcon={<FavoriteBorderOutlinedIcon />}>
          View Favorites
        </Button>
        <Button fullWidth variant="contained" onClick={handleClose}>
          View Store Cart
        </Button>
      </Stack>
    </DialogActions>
  </Dialog>
)

StoreCartModal.propTypes = {
  associateName: string,
  handleClose: func,
  handleFavorites: func,
  isOpen: bool,
  storeCartItems: number,
}

export default StoreCartModal
