import React from 'react'
import { func } from 'prop-types'

const CloseModal = ({ closeModal }) => {
  const handleOnclickLocally = e => {
    e.stopPropagation()
    closeModal(e)
  }
  return (
    <button
      type="button"
      className="close-modal"
      tabIndex="0"
      value="Close"
      aria-label="Close"
      onClick={handleOnclickLocally}
    >
      <img
        className="icon close"
        alt="close icon"
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
      />
    </button>
  )
}

CloseModal.propTypes = {
  closeModal: func,
}

export default CloseModal
